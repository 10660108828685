import './../css/dashboard.css';
import pic1 from './../image/images3.jpg';
import car from './../image/car.png';
import google from './../image/google.png';
import Delete from './delete.js'
import { FaFacebookSquare,FaTwitter,FaLinkedinIn,FaInstagram } from "react-icons/fa";
import React, { useState } from 'react';
function Dashboard()
{
    return(
        <div className="container">
            <div className="main">
                <div className='header'>
                    <div className='brand_area'>
                        <img className='logo' src={pic1} alt='error'></img>
                        <h1 className='title'>taxi</h1>
                    </div>
                    <div className='navbar'>
                        <ul>
                            <li>Home</li>
                            <li>Ticket</li>
                            <li>Contact</li>
                            <li>About us</li>
                            <li>Delete</li>
                        </ul>
                    </div>
                </div>
            <div className = 'content1'>
                <img className = 'car_img' src={car} alt="car"></img>
            </div>
            <h1 className = 'slogan'>Drive with us, it's the best.</h1>
            <div className='button'>
                <div className='two_button'>
                    <button className='download_btn'>Download</button>
                    <button className='login_btn'>Login</button>
                </div>
                <div className='one_button'>
                    <button className='delete_btn'>Delete</button>
                </div>
            </div>
            </div>
            <div className='footer'>
                <div className='footer1'>
                    <p>Customer app</p>
                    <img className = 'google' src = {google} alt = 'google'></img>
                    <p>Captain app</p>
                    <img className = 'google' src = {google} alt = 'google'></img>
                </div>
                <div className='footer1'>
                    <ul className = 'condition' >
                        <li>privacy policy</li>
                        <li>terms & condition</li>
                        <li>contact us</li>
                        <li>About</li>
                    </ul>
                </div>
                <div className='footer1'>
                    <h1>OUR SERVICE</h1>
                    <p>achivement</p>
                    <p>follow us</p>
                    <i><FaFacebookSquare /></i>
                    <i><FaTwitter /></i>
                    <i><FaLinkedinIn /></i>
                    <i><FaInstagram /></i>
                </div>
            </div>
            <div className = 'delete_comp'>
                <Delete />
            </div>
        </div>
    );  
}
export default Dashboard;