import './../css/delete.css';
import { IoMdCloseCircleOutline } from "react-icons/io";
function Delete()
{
    return(
        <div className = 'form_holder'>
            <i className='icon'><IoMdCloseCircleOutline /></i>
            <h1 className='head'>ACCOUNT TERMINATION</h1>
            <form>
                <input type='number' placeholder='Enter mobile'></input><br></br>
                <input className='submit' type='submit'></input>
            </form>
        </div>
    );
}
export default Delete;